import { makeStyles } from '@material-ui/core/styles';

const drawerLarge = 274;
const drawerDekstop = 175;
const drawerSmall = 120;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerLarge,
        flexShrink: 0,
        '@media (max-width: 1440px )': {
            width: drawerDekstop,
        },
        '@media (max-width: 1024px )': {
            width: drawerSmall,
        },
    },
    drawerPaper: {
        width: drawerLarge,
        background: '#2E3092',
        borderRight: '1px solid #DDDEE1',
        '@media (max-width: 1440px )': {
            width: drawerDekstop,
        },
        '@media (max-width: 1024px )': {
            width: drawerSmall,
        },
    },
    logoWrapper: {
        display: 'flex',
        paddingTop: 20,
        paddingBottom: 20,
        borderBottom: '1px solid #DDDEE1',
    },
    logo: {
        margin: '0 auto',
        '& img': {
            width: '150px',
            height: 'auto',
            '@media (max-width: 1440px )': {
                width: '100px',
            },
            '@media (max-width: 1024px )': {
                width: '80px',
            },
        },
    },
    menuWrapper: {
        paddingTop: '30px',
        paddingBottom: '30px',
        // border: '1px solid red',
    },
    menuContent: {
        marginTop: '40px',
        textAlign: 'center',
        paddingLeft: '60px',
        paddingRight: '60px',
        '@media (max-width: 1440px )': {
            marginTop: '20px',
            paddingLeft: '30px',
            paddingRight: '30px',
        },
        '@media (max-width: 1024px )': {
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    },
    imageMenu: {
        height: '125px',
        width: '125px',
        filter: 'drop-shadow(4px 5px 10px #000000)',
        '@media (max-width: 1440px )': {
            height: '70px',
            width: '70px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '50px',
            width: '50px',
        },
    },
    textMenu: {
        display: 'inline-block',
        marginTop: '20px',
        textTransform: 'uppercase',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#FFF',
        fontFamily: 'HartWell',
        textShadow: '4px 5px 10px rgba(0,0,0,0.6)',
        '@media (max-width: 1440px )': {
            fontSize: '14px',
            marginTop: '0px',
            display: 'block',
        },
        '@media (max-width: 1024px )': {
            fontSize: '12px',
        },
    },
    logoutWrapper: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    logout: {
        fontWeight: 'bold',
        border: 'none',
        padding: '6px 12px',
        cursor: 'pointer',
        color: '#2E3092',
    },
}));

export default useStyles;
