/* eslint-disable no-use-before-define */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-inner-declarations */
/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { custDataNameCookie, features } from '@config';
import { getHost } from '@helper_config';
import { breakPointsUp } from '@helper_theme';
import { getVendor } from '@helper_cookies';
import useStyles from './style';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import crypto from 'crypto';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import {
    addProductsToCartVendor,
    getGuestCartId as queryGetGuestCartId,
} from '@modules/product/services/graphql';
import { getCartId, setCartId } from '@helper_cartid';
import SideMenu from '../components/sidemenu';
import MobileSidemenu from '../components/sidemenu/MobileSidemenu';

const HeaderDesktop = dynamic(() => import('@common_headerdesktop'), { ssr: true });
const Message = dynamic(() => import('@common_toast'), { ssr: false });
const Loading = dynamic(() => import('@common_loaders/Backdrop'), { ssr: false });
// const ScrollToTop = dynamic(() => import('@common_scrolltotop'), { ssr: false });
const ScrollToBottom = dynamic(() => import('../components/ScrollToBottom'), { ssr: false });
const ShoppingBagIcon = dynamic(() => import('@modules/cart/plugins/ShoppingBag'), { ssr: false });

const Layout = (props) => {
    const footerStyles = useStyles();
    const {
        pageConfig,
        children,
        i18n, storeConfig = {},
        isLogin,
        t,
        vendorId,
        isUseSideMenu = true,
        noPaddingPage = false,
    } = props;
    const {
        ogContent = {}, schemaOrg = null, headerDesktop = true, footer = true, titleHeader = '',
    } = pageConfig;
    const router = useRouter();
    const [state, setState] = useState({
        toastMessage: {
            open: false,
            variant: '',
            text: '',
        },
        backdropLoader: false,
    });
    // const [mainMinimumHeight, setMainMinimumHeight] = useState(0);
    const [sideMenuOpen, setSideMenuOpen] = useState(false);

    const openSideMenu = () => {
        setSideMenuOpen(true);
    };

    const closeSideMenu = () => {
        setSideMenuOpen(false);
    };

    const handleSetToast = (message) => {
        setState({
            ...state,
            toastMessage: {
                ...state.toastMessage,
                ...message,
            },
        });
    };

    const handleLoader = (status = false) => {
        setState({
            ...state,
            backdropLoader: status,
        });
    };

    const handleCloseMessage = () => {
        setState({
            ...state,
            toastMessage: {
                ...state.toastMessage,
                open: false,
            },
        });
    };

    const ogData = {
        'og:title': pageConfig.title ? pageConfig.title : storeConfig.default_title ? storeConfig.default_title : 'Swift Pwa',
        'og:image': storeConfig.header_logo_src
            ? `${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`
            : `${getHost()}/assets/img/swift-logo.png`,
        'og:image:type': 'image/png',
        'og:url': `${getHost()}${router.asPath}`,
        'og:locale': i18n && i18n.language === 'id' ? 'id_ID' : 'en_US',
        'og:type': 'website',
        ...ogContent,
    };

    if (!ogData['og:description']) {
        ogData['og:description'] = pageConfig.description ? pageConfig.description : storeConfig.default_description || '';
    }

    if (features.facebookMetaId.enabled) {
        ogData['fb:app_id'] = features.facebookMetaId.app_id;
    }
    const [addToCartSimple] = addProductsToCartVendor();
    const [getGuestCartId] = queryGetGuestCartId();
    const canonicalUrl = `${getHost()}${router.asPath}`;
    const defaultLang = i18n && i18n.language === 'id' ? 'id_ID' : 'en_US';

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.toastMessage = handleSetToast;
            window.backdropLoader = handleLoader;
            const custData = Cookies.getJSON(custDataNameCookie);
            const tagManagerArgs = {
                dataLayer: {
                    pageName: pageConfig.title,
                    customerGroup: isLogin === 1 ? 'GENERAL' : 'NOT LOGGED IN',
                },
            };
            if (custData && custData.email) {
                tagManagerArgs.dataLayer.customerId = custData.email;
                tagManagerArgs.dataLayer.eid = crypto.createHash('sha256').update(custData.email).digest('hex');
            }
            TagManager.dataLayer(tagManagerArgs);

            // add to cart from window.AddToCart

            const addToCart = async (sku, qty) => {
                const cartId = getCartId();
                if (!cartId) {
                    try {
                        const guestCart = await getGuestCartId();
                        if (!guestCart) throw guestCart;

                        setCartId(guestCart.data.createEmptyCart);

                        const responseAddSimpleProductToCart = await addToCartSimple({
                            variables: {
                                cartId: guestCart.data.createEmptyCart,
                                sku,
                                qty: parseFloat(qty),
                                vendor_id: parseInt(getVendor() || vendorId, 10),
                                is_pickup: 0,
                            },
                        }).then((res) => {
                            const resErrorMessage = res
                                && res.data
                                && res.data.addProductsToCartVendor
                                && res.data.addProductsToCartVendor.user_errors
                                && res.data.addProductsToCartVendor.user_errors[0]
                                && res.data.addProductsToCartVendor.user_errors[0].message;
                            if (resErrorMessage) {
                                // eslint-disable-next-line no-console
                                console.log(resErrorMessage);
                            } else {
                                // eslint-disable-next-line no-console
                                console.log(t('product:successAddCart'));
                            }
                        });
                        if (!responseAddSimpleProductToCart) throw responseAddSimpleProductToCart;
                    } catch (error) {
                        // console.log(error);
                    }
                } else {
                    try {
                        const responseAddSimpleProductToCart = await addToCartSimple({
                            variables: {
                                cartId,
                                sku,
                                qty: parseFloat(qty),
                                vendor_id: parseInt(getVendor() || vendorId, 10),
                                is_pickup: 0,
                            },
                        }).then((res) => {
                            const resErrorMessage = res
                                && res.data
                                && res.data.addProductsToCartVendor
                                && res.data.addProductsToCartVendor.user_errors
                                && res.data.addProductsToCartVendor.user_errors[0]
                                && res.data.addProductsToCartVendor.user_errors[0].message;
                            if (resErrorMessage) {
                                // eslint-disable-next-line no-console
                                console.log(resErrorMessage);
                            } else {
                                // eslint-disable-next-line no-console
                                console.log(t('product:successAddCart'));
                            }
                        });
                        if (!responseAddSimpleProductToCart) throw responseAddSimpleProductToCart;
                    } catch (error) {
                        // console.log(error);
                    }
                }
            };

            window.AddToCart = addToCart;
        }
        // setMainMinimumHeight(refFooter.current.clientHeight + refHeader.current.clientHeight);
    }, []);

    const desktop = breakPointsUp('sm');

    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    const styles = {
        marginBottom: pageConfig.bottomNav && !isDesktop ? '50px' : 0,
        flexGrow: 1,
        minHeight: '100vh',
    };

    return (
        <>
            <Head>
                <meta
                    name="title"
                    content={pageConfig.metaTitle ? pageConfig.metaTitle
                        : pageConfig.title ? pageConfig.title
                            : storeConfig.default_title ? storeConfig.default_title : 'Swift Pwa'}
                />
                <meta
                    name="keywords"
                    content={pageConfig.keywords ? pageConfig.keywords
                        : pageConfig.title ? pageConfig.title
                            : storeConfig.default_title ? storeConfig.default_title : 'Swift Pwa'}
                />
                <meta name="description" content={ogData['og:description']} />
                <meta name="robots" content="INDEX,FOLLOW" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                {Object.keys(ogData).map((key, idx) => {
                    if (typeof ogData[key] === 'object' && ogData[key].type && ogData[key].type === 'meta') {
                        return <meta name={`${key}`} content={ogData[key].value} key={idx} />;
                    }
                    return <meta property={`${key}`} content={ogData[key]} key={idx} />;
                })}
                <title>{pageConfig.title ? pageConfig.title : storeConfig.default_title ? storeConfig.default_title : 'Swift Pwa'}</title>
                {schemaOrg
                    ? (
                        schemaOrg.map((val, idx) => (
                            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(val) }} key={idx} />
                        ))
                    ) : null}

                <link
                    rel="canonical"
                    href={pageConfig?.canonical ? pageConfig.canonical : canonicalUrl.substring(0, canonicalUrl.indexOf('?') !== -1 ? canonicalUrl.indexOf('?') : canonicalUrl.length)}
                />
                <link
                    rel="alternate"
                    hrefLang={defaultLang}
                    href={canonicalUrl.substring(0, canonicalUrl.indexOf('?') !== -1 ? canonicalUrl.indexOf('?') : canonicalUrl.length)}
                />
            </Head>
            <div className={footerStyles.mainContentWrapper}>
                {
                    isUseSideMenu ? (
                        <>
                            <SideMenu
                                storeConfig={storeConfig}
                            />
                            <MobileSidemenu
                                open={sideMenuOpen}
                                onClose={closeSideMenu}
                                onOpen={openSideMenu}
                            />
                        </>
                    ) : null
                }
                <main
                    style={{ ...styles }}
                    className={classNames(
                        noPaddingPage ? footerStyles.mainNoPadding : footerStyles.mainContent,
                    )}
                    id="maincontent"
                >
                    <Loading open={state.backdropLoader} />
                    <Message
                        open={state.toastMessage.open}
                        variant={state.toastMessage.variant}
                        setOpen={handleCloseMessage}
                        message={state.toastMessage.text}
                    />
                    {
                        headerDesktop ? (
                            <div>
                                <HeaderDesktop
                                    storeConfig={storeConfig}
                                    isLogin={isLogin}
                                    t={t}
                                    title={titleHeader || pageConfig?.title || ''}
                                />
                            </div>
                        ) : null
                    }
                    {children}
                    {/* {desktop ? <ScrollToTop {...props} /> : null} */}
                    <ScrollToBottom />
                    {
                        footer ? (
                            <div
                                className={isUseSideMenu
                                    ? footerStyles.shoppingBagContainer
                                    : footerStyles.shoppingNoPadding}
                            >
                                {
                                    isUseSideMenu ? (
                                        <IconButton
                                            color="primary"
                                            aria-label="open drawer"
                                            edge="end"
                                            onClick={openSideMenu}
                                            className={classNames('hidden-desktop', footerStyles.menu)}
                                        >
                                            <MenuIcon fontSize="large" />
                                        </IconButton>
                                    ) : null
                                }
                                <div className={footerStyles.shopinggWrapper}>
                                    <ShoppingBagIcon bottomCart />
                                </div>
                            </div>
                        ) : null
                    }
                </main>
            </div>
            <style jsx global>
                {`
                .MuiSnackbar-root {
                    z-index: 10000 !important;
                }
                #ymDivCircle {
                    display: ${desktop ? 'block' : 'none'} !important;
                }
                #ym-notification {
                    display: none;
                }
                `}
            </style>
        </>
    );
};

export default Layout;
