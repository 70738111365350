import makeStyles from '@material-ui/core/styles/makeStyles';

const drawerLarge = 274;
const drawerDekstop = 175;
const drawerSmall = 120;

const useStyles = makeStyles((theme) => ({
    mainContentWrapper: {
        width: '100%',
        display: 'flex',
        overflow: 'hidden',
    },
    mainContent: {
        padding: '30px 30px',
        '@media (max-width: 1024px )': {
            padding: '30px 30px',
        },
    },
    mainNoPadding: {
        padding: '0',
    },
    footerContainer: {
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    },
    contentWrap: {
        '&.fullWidth': {
            width: '100% !important',
        },
        [theme.breakpoints.up('sm')]: {
            width: '95%',
        },
        [theme.breakpoints.up('xs')]: {
            width: '90%',
        },
    },
    marginTopMain: {
        '@media (min-width: 768px)': {
            marginTop: '180px !important',
        },
    },
    shoppingBagContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        background: '#D6E0ED',
        width: '100%',
        paddingLeft: drawerLarge,
        // height: '100px',
        '@media (max-width: 1440px )': {
            paddingLeft: drawerDekstop,
        },
        '@media (max-width: 1024px )': {
            paddingLeft: drawerSmall,
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 0,
        },
    },
    shopinggWrapper: {
        width: '100%',
    },
    menu: {
        // border: '1px solid red',
        padding: '0 30px',
    },
    shoppingNoPadding: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        background: '#D6E0ED',
        width: '100%',
        // height: '100px',
        // height: '100px',
    },
}));

export default useStyles;
