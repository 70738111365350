import React from 'react';
import Link from 'next/link';
import Router from 'next/router';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import classNames from 'classnames';
import { removeIsLoginFlaggingEcatalog } from '@root/src/helpers/authEcatalog';
import { removeIsLoginFlagging } from '@helper_auth';
import { removeCartId } from '@helper_cartid';
import Cookies from 'js-cookie';
import { setCookies } from '@helper_cookies';
import { useApolloClient } from '@apollo/client';
import { localTotalCart } from '@services/graphql/schema/local';
import isUseVirtualKeyboard from '@root/src/helpers/virtualKeyboard';
import useStyles from './style';

const MobileSidemenu = (props) => {
    const {
        open,
        onClose,
        onOpen,
    } = props;
    const styles = useStyles();

    const client = useApolloClient();

    const handleLogout = () => {
        removeIsLoginFlaggingEcatalog();
        Cookies.set('customerB2B', { status: false });
        setCookies('coupon', []);
        removeIsLoginFlagging();
        removeCartId();
        localStorage.removeItem('xdt_customer_id');
        client.writeQuery({ query: localTotalCart, data: { totalCart: 0 } });
        Router.push('/customer/account/login');
    };

    return (
        <SwipeableDrawer
            anchor="left"
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            className={classNames(styles.drawer, 'hidden-desktop')}
            classes={{
                paper: styles.drawerPaper,
            }}
        >
            <div className={styles.menuWrapper}>
                {/* <ShoppingAt /> */}
                <div className={styles.menuContent}>
                    <Link href="/">
                        <a>
                            <img
                                src="/assets/img/home_ecatalog_logo.png"
                                alt="home"
                                width={125}
                                height={125}
                                className={styles.imageMenu}
                            />
                            <span className={styles.textMenu}>Home</span>
                        </a>
                    </Link>
                </div>
                <div className={styles.menuContent}>
                    <Link href="/catalogsearch/result?q=">
                        <a>
                            <img
                                src="/assets/img/search1.png"
                                alt="catalogseach"
                                width={125}
                                height={125}
                                className={styles.imageMenu}
                            />
                            <span className={styles.textMenu}>Search</span>
                        </a>
                    </Link>
                </div>
                <div className={styles.menuContent}>
                    <Link href="/brands">
                        <a>
                            <img
                                src="/assets/img/shop-by-brand1.png"
                                alt="catalogbrand"
                                width={125}
                                height={125}
                                className={styles.imageMenu}
                            />
                            <span className={styles.textMenu}>Shop by brand</span>
                        </a>
                    </Link>
                </div>
                <div className={styles.menuContent}>
                    <Link href="/ecatalog-promotion">
                        <a>
                            <img
                                src="/assets/img/promo1.png"
                                alt="catalogpromo"
                                width={125}
                                height={125}
                                className={styles.imageMenu}
                            />
                            <span className={styles.textMenu}>Promo</span>
                        </a>
                    </Link>
                </div>
                <div className={styles.menuContent}>
                    <Link href="/ecatalog-recomendation">
                        <a>
                            <img
                                src="/assets/img/rekomendasi1.png"
                                alt="catalogrecomendation"
                                width={125}
                                height={125}
                                className={styles.imageMenu}
                            />
                            <span className={styles.textMenu}>Rekomendasi</span>
                        </a>
                    </Link>
                </div>
            </div>
            {
                !isUseVirtualKeyboard() ? (
                    <div className={styles.logoutWrapper}>
                        <button
                            type="button"
                            className={styles.logout}
                            onClick={handleLogout}
                        >
                            Logout
                        </button>
                    </div>
                ) : null
            }
        </SwipeableDrawer>
    );
};

export default MobileSidemenu;
