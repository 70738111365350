import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import * as Schema from './schema';

export const getProduct = (urlpath, vendorId) => useQuery(Schema.getProduct(urlpath, vendorId));
export const getConfigurableProduct = (sku) => useQuery(Schema.getConfigurableProduct(sku));
export const getBundleProduct = (sku) => useQuery(Schema.getBundleProduct(sku));
export const getInstallationGuide = (params = {}) => useQuery(Schema.getInstalationGuideSchema, {
    ...params,
});

export const getReviews = (params) => useQuery(Schema.getReview(), {
    variables: {
        ...params,
    },
});

export const addReview = () => useMutation(Schema.addReview, {
    context: {
        request: 'internal',
    },
});

export const addSimpleProductsToCart = () => useMutation(Schema.addSimpleProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addProductsToCartVendor = () => useMutation(Schema.addProductsToCartVendor, {
    context: {
        request: 'internal',
    },
});

export const addVirtualProductToCart = () => useMutation(Schema.addVirtualProductToCart, {
    context: {
        request: 'internal',
    },
});

export const addConfigProductsToCart = () => useMutation(Schema.addConfigProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addBundleProductsToCart = () => useMutation(Schema.addBundleProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addWishlist = () => useMutation(Schema.addWishlist, {
    context: {
        request: 'internal',
    },
});

export const getGuestCartId = () => useMutation(Schema.createCartIdGuest);
export const getCustomerCartId = () => useLazyQuery(Schema.getCartIdUser, {
    context: {
        request: 'internal',
    },
    skip: typeof window === 'undefined',
    fetchPolicy: 'no-cache',
});

export const getAvailableSource = (params) => useQuery(Schema.getAvailableSource, {
    variables: {
        ...params,
    },
    fetchPolicy: 'no-cache',
});

export const getCartLazyQuery = () => useLazyQuery(Schema.getCart, {
    context: {
        request: 'internal',
    },
    fetchPolicy: 'no-cache',
});

export const getXenditKredivoPaylaterPlans = () => useLazyQuery(Schema.getXenditKredivoPaylaterPlans, {
    context: {
        request: 'internal',
    },
    fetchPolicy: 'no-cache',
});

export const getCustomizableOptions = (urlKey) => useQuery(Schema.customizableOptionsQuery, {
    context: {
        request: 'internal',
    },
    variables: { urlKey },
    fetchPolicy: 'no-cache',
});

export default { getProduct, getAvailableSource };
